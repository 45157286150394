// eslint-disable-next-line import/named
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { CURRENT_USER_ID, IS_NOTIFICATION_SYNCED } from './localStorageKeys';
import { VerifyRequest } from '../models/VerifyRequest';
import { verificationService } from '../services/authenticationService';
import { strings } from './Strings';

const invalidTokenError = 'Invalid token';
export function setCookie(cname: string, hours: number, cvalue?: string) {
    const d = new Date();
    d.setTime(d.getTime() + hours * 60 * 60 * 1000); // (exdays * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;
    if (window !== undefined) {
        const host = window.location.hostname;
        if (host?.includes(strings.epocrates_domain)) {
            const domain = `domain=${strings.epocrates_domain}`;
            document.cookie = `${cname}=${cvalue};${expires};path=/;${domain}`;
        } else {
            document.cookie = `${cname}=${cvalue};${expires};path=/`;
        }
    }
}

export function getCookie(cname: string) {
    const name = `${cname}=`;
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return '';
}

export function checkCookie() {
    const user = getCookie('sessionTimer');
    if (user !== '') {
        return user;
    }
    return null;
}
async function fetchAccessToken(userId: string, refreshToken: string) {
    const verifyRequest: VerifyRequest = { userId, refreshToken };
    const request = { verifyRequest };
    const response = await verificationService(request);
    if (response?.data?.token) {
        setCookie('sessionTimer', 24, 'sessionTimer');
        setCookie('accessToken', 24, response.data.token);
        setCookie('currentUserId', 24, userId);
        return response.data.token;
    }
    throw new Error(invalidTokenError);
}

//
// If it's passed a valid jwt that is more than 10 seconds from expiring, returns false (meaning no renewal needed);
// If passed null, or an expired jwt, or a jwt that expires in less than 10 seconds, returns true (indicating a renewal is needed)
//
export function tokenNeedsRenewed(jwt: string | null): boolean {
    if (!jwt) return true;
    const decoded = jwt_decode<JwtPayload>(jwt);
    const expirationSeconds = decoded.exp;
    const nowSeconds = new Date().getTime() / 1000.0;
    if (expirationSeconds) {
        if (expirationSeconds - nowSeconds < 10.0) {
            // This is expired or expiring imminently.
            return true;
        }
        // Still valid so use what we already have
        return false;
    }
    return false;
}

//
// Renews accessToken if it is close to expiring, but throws Error if refreshToken is not available
// or userId doesn't exist (i.e. user isn't attempted to be logged in)
//
export async function renewAccessToken() {
    const refreshToken = getCookie('refreshToken');
    const userId = localStorage.getItem(CURRENT_USER_ID);
    const accessToken = getCookie('accessToken');
    if (userId && refreshToken) {
        if (tokenNeedsRenewed(accessToken)) {
            return fetchAccessToken(userId, refreshToken);
        }
        return accessToken;
    }
    throw new Error(invalidTokenError);
}

export async function refreshAccess(): Promise<string> {
    try {
        const cookie = await renewAccessToken();
        return cookie;
    } catch (e) {
        window.location.href = `${window.location.origin}/login?refernext=${encodeURIComponent(
            window.location.href
        )}`;
        throw e;
    }
}

export function setUserId(userId: number) {
    setCookie('sessionTimer', 24, 'sessionTimer');
    localStorage.setItem(CURRENT_USER_ID, userId.toString());
}

export function setAccesstoken(accessToken: string) {
    setCookie('accessToken', 24, accessToken);
}

export function setRefreshToken(refreshToken: string) {
    setCookie('refreshToken', 24, refreshToken);
}

//
// Returns true when the user is attempted to be authenticated (including
// if there's an expired accessToken)
//
export function isAuthenticatedUser(): boolean {
    return (
        !!getCookie('accessToken') && !!getCookie('sessionTimer') && !!getCookie('currentUserId')
    );
}

//
// Checks if we have the user access token, and it's in date; then returns it;
// else renews it.
//
export async function getCurrentAccessTokenOrRequestLogin(): Promise<string> {
    const accessToken = getCookie('accessToken');
    if (tokenNeedsRenewed(accessToken)) {
        return refreshAccess();
    }
    return accessToken;
}

//
// Returns a full Authorization HTTP header or nothing, depending on current
// auth state and whether authMode requires it
//
export async function getApiAuthorizationHeader(
    authMode: 'always' | 'auto' | 'never' = 'always'
): Promise<string | undefined> {
    if (authMode === 'never') {
        return undefined;
    }

    // Check if we're *trying* to be authenticated currently, based on presence of accessToken cookie
    const isAuthenticationAttempted = isAuthenticatedUser();

    // When mode is auto, but user is not authenticated -> don't try to authenticate or pass Authorization header
    if (authMode === 'auto' && !isAuthenticationAttempted) {
        return undefined;
    }

    // Either we're in 'auto' and there *is* an intent to be authenticated (accessToken), or we're in 'always' mode.
    // Must make sure token is refreshed in this case, and prompt if not.
    return 'Bearer ' + (await getCurrentAccessTokenOrRequestLogin());
}

//
// Clears all user tokens and id's so user is fully logged out of *.epocrates.com
//
export function logoutUser() {
    setCookie('sessionTimer', -1, '');
    setCookie('accessToken', -1, '');
    setCookie('refreshToken', -1, '');
    setCookie('currentUserId', -1, '');
    localStorage.removeItem(CURRENT_USER_ID);
    localStorage.removeItem(IS_NOTIFICATION_SYNCED);
}

//
// Returns either the numeric user id (oracle id) when available, or undefined
//
export function getCurrentUserId(): number | undefined {
    const currentUserIdString = getCookie('currentUserId');
    if (!currentUserIdString) {
        return undefined;
    }
    return +currentUserIdString;
}

//
// Writes the various cookies and storage that contain user's auth/login state
//
export function updateUserCookies(
    userId: number,
    accessToken: string | null,
    refreshToken: string | null
) {
    const currentUserId = getCurrentUserId();
    if (currentUserId !== userId) {
        logoutUser();
    }
    setCookie('currentUserId', 24, userId.toString());
    localStorage.setItem(CURRENT_USER_ID, userId.toString());
    setCookie('sessionTimer', 24, 'sessionTimer');
    if (accessToken) {
        setAccesstoken(accessToken);
    }
    if (refreshToken) {
        setRefreshToken(refreshToken);
    }
}
