import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './styles/style.css';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material/className';
import { classPrefix } from './utils/constants';
import { store, persistor } from './store/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import { CURRENT_USER_ID } from './utils/localStorageKeys';
import config from './config/envConfig';
import { StrictMode } from 'react';
import { AppWrapper } from './components/AppWrapper';

ClassNameGenerator.configure((componentName) => `${classPrefix}-${componentName}`);

const queryClient = new QueryClient();

const { optimizelyKey } = config.environment;

const optimizely = createInstance({
    sdkKey: optimizelyKey,
    datafileOptions: {
        autoUpdate: true,
        updateInterval: 300000, // update every 5 minutes
    },
});
const userId = localStorage.getItem(CURRENT_USER_ID)?.toString();
const user = userId
    ? {
          id: userId,
          attributes: {
              userId: userId,
              platform: 'Web',
          },
      }
    : {
          id: '-1',
      };

render(
    <StrictMode>
        <OptimizelyProvider optimizely={optimizely} timeout={300000} user={user}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <QueryClientProvider client={queryClient}>
                        <BrowserRouter>
                            <AppWrapper />
                        </BrowserRouter>
                        <ReactQueryDevtools initialIsOpen={false} position={'top-right'} />
                    </QueryClientProvider>
                </PersistGate>
            </Provider>
        </OptimizelyProvider>
    </StrictMode>,
    document.getElementById('root')
);
