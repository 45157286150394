import { ReactNode, useEffect, useState } from 'react';
import { getCookie } from '@athena/epoc-frontend-library/dist/utils/cookies';
import { Navigation } from '@athena/epoc-frontend-library/dist/components/Navigation';
import { Footer } from '@athena/epoc-frontend-library/dist/components/Footer';
import { LoginBanner } from '@athena/epoc-frontend-library/dist/components/baseComponents/LoginBanner';
import { Box } from '@mui/material';
import { GetAccountProfile } from '../../queries/AccountProfileQuery';
import { CURRENT_USER_ID } from '../../utils/localStorageKeys';
import { GetUserSubscription } from '../../queries/GetUserSubscription';
import { useDecision } from '@optimizely/react-sdk';
import {
    eolRecentlyViewedFlag,
    webGlobalSavedFlag,
    webLoginBannerFlag,
    notificationCenterFlag,
    notificationBadgeFlag,
    hnsLandingFlag,
} from '../../utils/optimizelyFlags';
import { isAuthenticatedUser } from '../../utils/cookies';
import { getSubscriptionType } from '../../utils/getSubscriptionType';

export interface HeaderProps {
    firstName?: string | undefined;
    lastName?: string | undefined;
    email?: string | undefined;
    isPremiumUser: boolean;
    isUserAuthenticated: boolean;
}

export function ScreenLayout({ children }: { children: ReactNode }) {
    let [headerProps, setHeaderProps] = useState<HeaderProps>({
        isUserAuthenticated: false,
        isPremiumUser: false,
    });
    const footer = <Footer isPremium={headerProps.isPremiumUser} />;

    const { data: profileData } = GetAccountProfile();
    const { data: subscriptionData } = GetUserSubscription(profileData?.data?.profile?.userId);

    useEffect(() => {
        if (profileData?.data?.profile) {
            if (subscriptionData) {
                const userPremiumStatus: boolean = getSubscriptionType(subscriptionData);
                headerProps = {
                    firstName: profileData?.data?.profile?.firstName,
                    lastName: profileData?.data?.profile?.lastName,
                    email: profileData?.data?.profile?.email,
                    isPremiumUser: userPremiumStatus,
                    isUserAuthenticated: true,
                };
                setHeaderProps(headerProps);
            } else {
                headerProps = {
                    firstName: profileData?.data?.profile?.firstName,
                    lastName: profileData?.data?.profile?.lastName,
                    email: profileData?.data?.profile?.email,
                    isPremiumUser: false,
                    isUserAuthenticated: true,
                };
                setHeaderProps(headerProps);
            }
        }
    }, [profileData, subscriptionData]);

    const [loginBannerEnabled, clientReady] = useDecision(webLoginBannerFlag);
    const [savedEnabled] = useDecision(webGlobalSavedFlag);
    const [recentlyViewedEnabled] = useDecision(eolRecentlyViewedFlag);
    const [notificationDecision] = useDecision(notificationCenterFlag);
    const [notificationBadgeDecision] = useDecision(notificationBadgeFlag);
    const [hnsDecision] = useDecision(hnsLandingFlag);
    const optimizelyVariationOff = 'Off';
    const [sideNavExpandedState, setSideNavExpandedState] = useState(
        getCookie('expanded') === 'true'
    );

    const firstNameStr = headerProps.firstName ?? '';
    const lastNameStr = headerProps.lastName ?? '';

    const userDetails = {
        isRegistered: headerProps.isUserAuthenticated,
        isPremium: headerProps.isPremiumUser,
        userInitials: firstNameStr.charAt(0).toUpperCase() + lastNameStr.charAt(0).toUpperCase(),
        firstName: firstNameStr,
        lastName: lastNameStr,
        userId: localStorage.getItem(CURRENT_USER_ID) || getCookie(CURRENT_USER_ID),
    };

    const handleRoute = (path: string) => {
        window.location.href = path;
    };

    const handleWindowRoute = (path: string) => {
        window.location.href = path;
    };

    useEffect(() => {
        setSideNavExpandedState(false);
    }, []);

    return (
        <Box
            sx={{
                height: '100%',
                '@media (max-width: 1199px)': {
                    marginLeft: '0px',
                    width: '100%',
                },
                marginLeft: 'auto',
                width: 'calc(100% - 72px)',
            }}
        >
            <Navigation
                sideNavigationEnabled={true}
                expanded={sideNavExpandedState}
                userDetails={userDetails}
                setSideNavExpandedState={setSideNavExpandedState}
                isRegistered={headerProps.isUserAuthenticated}
                isPremium={headerProps.isPremiumUser}
                firstName={headerProps.firstName}
                lastName={headerProps.lastName}
                email={headerProps.email}
                handleRoute={handleRoute}
                handleWindowRoute={handleWindowRoute}
                isHnSEnabled={hnsDecision.enabled}
                savedEnabled={savedEnabled.enabled}
                recentlyViewedEnabled={recentlyViewedEnabled.enabled}
                isNotificationEnabled={notificationDecision.enabled}
                notificationVariation={
                    notificationBadgeDecision.enabled
                        ? notificationBadgeDecision.variationKey ?? optimizelyVariationOff
                        : optimizelyVariationOff
                }
            />
            <Box
                sx={{
                    marginLeft: 0,
                    '@media only screen and (min-width: 1200px)': {
                        marginLeft: '-72px',
                    },
                }}
            >
                {children}
            </Box>
            {footer}
            {!isAuthenticatedUser() && clientReady && (
                <LoginBanner isEnabled={loginBannerEnabled.enabled} />
            )}
        </Box>
    );
}
